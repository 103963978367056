import { BOOKER_EMAIL_QUESTION_ID, BOOKER_NOTES_QUESTION_ID, BOOKING_START_QUESTION_ID } from '@/constants/constants';
import { ILocale } from '@/constants/locales';
import { TTE, TTLANG, TTS, TTU, TTZ } from '@/constants/queryParams';
import { ROUTE_BOOKING_DETAILS, ROUTE_BOOKING_PAGE } from '@/constants/routes';
import useBookingPagePreview from '@/hooks/useBookingPagePreview';
import { useSearchParams } from '@/hooks/useSearchParams';
import i18n from '@/i18n';
import { useCreateBooking } from '@/queries/bookings.queries';
import { useAvailability } from '@/queries/useAvailability';
import queryParamsService from '@/services/queryParams.service';
import themeService from '@/services/theme.service';
import timeZoneService from '@/services/timezone.service';
import timetimeTheme from '@/themes';
import IQuestion from '@/types/IQuestion';
import ITimeZone from '@/types/ITimeZone';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { checkAvailability } from './booking-form.service';

export function useBookingFormPage() {
	const { t } = useTranslation();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const params = useSearchParams();
	const timeZone = params.get(TTZ) ? (params.get(TTZ) as ITimeZone) : (timeZoneService.getTimeZone() as ITimeZone);
	const start = DateTime.fromISO(params.get(TTS)!);
	const end = DateTime.fromISO(params.get(TTE)!);
	const units = params.has(TTU) ? Number(params.get(TTU)) : undefined;
	const locale = (params.get(TTLANG) as ILocale) ?? (i18n.language as ILocale);
	// We are checking a specific availability for a single day
	const days = 1;
	// Check the availability for the whole day
	const from = start.startOf('day');
	const query = useAvailability({ id, from, units, days, locale });
	const color = query.data?.eventTypeTags?.ttTheme || 'primary';
	const createBookingMutation = useCreateBooking();
	const queryClient = useQueryClient();
	const { isPreviewLoading } = useBookingPagePreview({ queryClient });
	const questions = _getQuestions(query.data?.questions || [], t);

	const hasAvailability = checkAvailability({
		from: start,
		to: end,
		timeZone,
		slots: query.data?.timeSlots || [],
	});

	function handleBookingCreated(params: { answers: Record<string, string> }) {
		if (!query.data) {
			throw Error('No query data found');
		}

		// In the future email and notes will be regular questions
		const mail = params.answers[BOOKER_EMAIL_QUESTION_ID];
		const notes = params.answers[BOOKER_NOTES_QUESTION_ID];
		delete params.answers[BOOKER_NOTES_QUESTION_ID];
		const start = params.answers[BOOKING_START_QUESTION_ID];
		delete params.answers[BOOKING_START_QUESTION_ID];

		return createBookingMutation
			.mutateAsync({
				answers: params.answers,
				eventTypeId: query.data.id,
				mail,
				notes,
				start: new Date(start),
				units: units,
			})
			.then((d) => {
				history.push(ROUTE_BOOKING_DETAILS.replace(':id', d.data.id));
			});
	}

	function onBackClick() {
		const search = queryParamsService.buildSearchParams({
			params: {
				[TTS]: '',
				[TTE]: '',
			},
			originalParams: params,
		});

		history.push({
			pathname: ROUTE_BOOKING_PAGE.replace(':id', id),
			search: search,
		});
	}

	return {
		handleBookingCreated,
		onBackClick,
		end,
		hasAvailability,
		id,
		isPreviewLoading,
		locale,
		privacyPolicyUrl: query.data?.eventTypeTags?.ttGDPRMode,
		query,
		questions,
		start,
		theme: themeService.customizeWithPrimaryColor(timetimeTheme, color),
		timeZone,
	};
}

/**
 * Email used to be a separate field now it is a question with a known id.
 *
 * This function creates a backward compatibility behavior.
 * When no email question is given it adds one.
 */
function _getQuestions(questions: Array<IQuestion>, t: any): Array<IQuestion> {
	if (questions.some((q) => q.id === BOOKER_EMAIL_QUESTION_ID)) {
		return questions;
	}

	return [
		{
			id: BOOKER_EMAIL_QUESTION_ID,
			label: t('Email'),
			required: true,
			type: 'EMAIL',
		},
		...questions,
	];
}
