import { BOOKER_NOTES_QUESTION_ID, BOOKING_START_QUESTION_ID } from '@/constants/constants';
import { ILocale } from '@/constants/locales';
import IQuestion from '@/types/IQuestion';
import ITimeZone from '@/types/ITimeZone';
import { Box, Button, Checkbox, FormControl, FormLabel, Input, Link, Textarea } from '@chakra-ui/react';
import DateInfo from './components/DateInfo';
import FormQuestion from './components/form-question/form-question.component';
import useBookingForm from './useBookingForm';

interface IBookingFormProps {
	end: Date;
	eventTypeTimezone: ITimeZone;
	locale: ILocale;
	privacyPolicyUrl?: string;
	questions: Array<IQuestion>;
	start: Date;
	timeZone: ITimeZone;
	onBookingConfirmed: (params: { answers: Record<string, string> }) => void;
	onBackClick: () => void;
}

export default function BookingForm(props: IBookingFormProps) {
	const { t, form, handleSubmit } = useBookingForm(props);

	return (
		<Box display="grid" h="100dvh" overflow="auto" p={{ base: 6, md: 8 }} w="100%">
			<Box
				w="full"
				maxW={{ base: 'full', lg: 'md' }}
				display="grid"
				gridTemplateRows="1fr auto"
				as="form"
				onSubmit={handleSubmit}
				mx="auto"
			>
				<Box overflow="auto">
					<DateInfo
						locale={props.locale}
						start={props.start}
						end={props.end}
						timeZone={props.timeZone}
						eventTypeTimezone={props.eventTypeTimezone}
					/>

					<Input type="hidden" {...form.register(BOOKING_START_QUESTION_ID)} />

					{props.questions.map((question) => (
						<FormQuestion key={question.id} form={form} question={question} />
					))}

					<FormControl mt="4" isInvalid={!!form.formState.errors[BOOKER_NOTES_QUESTION_ID]}>
						<FormLabel>{t('Booking notes')}</FormLabel>
						<Textarea
							placeholder={t('Write any comments here.')}
							{...form.register(BOOKER_NOTES_QUESTION_ID)}
							variant="filled"
						/>
					</FormControl>

					{props.privacyPolicyUrl ? (
						<FormControl mt={4}>
							<FormLabel>{t('Privacy & terms')}</FormLabel>
							<Checkbox {...form.register('tt_gdpr_question', { required: true })}>
								{t('I agree to the')}{' '}
								<Link href={props.privacyPolicyUrl} target="_blank" color="blue.500">
									{t('privacy policy and terms.')}
								</Link>
							</Checkbox>
						</FormControl>
					) : null}
				</Box>

				<Box mt="auto">
					<Button
						w="100%"
						size={{ base: 'sm', md: 'md' }}
						colorScheme="blue"
						type="button"
						variant="link"
						onClick={props.onBackClick}
					>
						{t('Choose another time')}
					</Button>

					<Button
						mt="4"
						colorScheme="primary"
						isDisabled={!form.formState.isValid}
						isLoading={form.formState.isSubmitting}
						type="submit"
						w="100%"
					>
						{t('Confirm booking')}
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
