import { EP_AVAILABILITY } from '@/constants/endpoints';
import { ILocale } from '@/constants/locales';
import { IEventTypeTags } from '@/types/IEventType';
import IQuestion from '@/types/IQuestion';
import ITimeZone from '@/types/ITimeZone';
import ISlot from '@/types/Slot';
import { DateTime } from 'luxon';
import client from '../api_client/client';

export interface IGetAvailabilityResponse extends IGetPublicEventTypeInfoResponse {
	eventTypeTimeZone: ITimeZone;
	timeSlots: Array<ISlot>;
}

export interface IGetPublicEventTypeInfoResponse {
	availableUnits?: number | null;
	description: string;
	eventTypeTags?: IEventTypeTags;
	eventTypeTimeZone: ITimeZone | null;
	i18n: { availableLocales: Array<ILocale>; currentLocale: ILocale | null };
	id: string;
	imageUrl: string | null;
	maxConcurrentBookings?: number | null;
	maxUnitsPerBooking?: number | null;
	name: string;
	questions: Array<IQuestion>;
	unitsLabel?: string | null;
}

interface IGetParams {
	days?: number;
	from: DateTime;
	id: string;
	locale?: ILocale;
	units?: number;
}

async function get(params: IGetParams): Promise<IGetAvailabilityResponse> {
	return client
		.get<any>(`${EP_AVAILABILITY}/${params.id}/availability`, {
			params: {
				days: params.days || 6,
				from: params.from.toISODate()!,
				locale: params.locale,
				timeZone: params.from.zone.name,
				units: params.units,
			},
		})
		.then((res) => {
			const data = res.data;

			return {
				...data,
				timeSlots: data.timeSlots.map((raw: { start: string; end: string; score: number }) => ({
					start: DateTime.fromISO(raw.start, { zone: data.eventTypeTimeZone }),
					end: DateTime.fromISO(raw.end, { zone: data.eventTypeTimeZone }),
					score: raw.score,
				})),
			};
		});
}

interface IGetInfoArgs {
	id: string;
	locale: ILocale;
}
/**
 * Get the public information of an event-type without the timeSlots.
 */
async function getInfo(args: IGetInfoArgs) {
	return client
		.get<IGetPublicEventTypeInfoResponse>(`https://api.timetime.in/v1/public/event-types/${args.id}`, {
			params: { locale: args.locale },
		})
		.then((res) => res.data);
}

export default { get, getInfo };
