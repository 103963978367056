import useAuth from '@/hooks/useAuth';
import api_client from '@/repositories';
import bookingRepository from '@/repositories/booking.repository';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export interface ICreateBookingParams {
	answers: Record<string, string>;
	eventTypeId: string;
	mail: string;
	notes: string;
	start: Date;
	units?: number;
}

interface IUseListBookingsArgs {
	days?: number;
	/** IsoDate yyyy-mm */
	from?: string;
}

export function useListBookings(args?: IUseListBookingsArgs) {
	const auth = useAuth();
	const userId = auth.getCurrentUser().userId;

	return useQuery({
		queryKey: ['bookings', userId, args],
		queryFn: () => api_client.booking.list({ eventTypesUserId: userId, ...args }),
	});
}

export function useCreateBooking() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (params: ICreateBookingParams) => api_client.booking.create(params),
		onSuccess() {
			queryClient.invalidateQueries({ queryKey: ['bookings'] });
		},
	});
}

export function useCancelBooking(id: string) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: api_client.booking.cancel,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['bookings'] });
			queryClient.invalidateQueries({ queryKey: ['booking', id] });
		},
	});
}

export function useGetBooking(id: string) {
	return useQuery({
		queryKey: ['booking', id],
		queryFn: () => api_client.booking.get(id),
	});
}

export function useUpdateBookingMutation() {
	const client = useQueryClient();
	return useMutation({
		mutationFn: bookingRepository.update,
		onSuccess(_, { id }) {
			client.invalidateQueries({ queryKey: ['bookings'] });
			client.invalidateQueries({ queryKey: ['booking', id] });
		},
	});
}
