import AppLoader from '@/components/AppLoader';
import { Avatar, Box, ChakraProvider, Heading, Text, keyframes } from '@chakra-ui/react';
import { useBookingFormPage } from './booking-form.controller';
import BookingForm from './components/BookingForm/BookingForm';
import NoAvailabilityInfo from './components/no-availability-info/no-availability-info.view';

const animationKeyframes = keyframes`0% { transform: opacity: 0 } 100% { opacity: 1 }`;
const animation = `${animationKeyframes} .2s ease-in-out forwards`;

export default function BookingFormPage() {
	const {
		handleBookingCreated,
		onBackClick,
		end,
		hasAvailability,
		id,
		privacyPolicyUrl,
		query,
		questions,
		start,
		theme,
		timeZone,
		locale,
	} = useBookingFormPage();

	if (!id) {
		return <Text> No id </Text>;
	}

	if (!query.data) {
		return (
			<Box h="100dvh" p="4" bg="white" overflow="auto">
				<AppLoader />
			</Box>
		);
	}

	return (
		<ChakraProvider theme={theme}>
			<Box
				opacity={0}
				animation={animation}
				h="100dvh"
				bg="white"
				overflow="auto"
				display="grid"
				gridTemplateColumns={{ base: '1fr', lg: '50% 50%' }}
			>
				<Box
					backgroundColor="primary.500"
					p={10}
					display={{ base: 'none', lg: 'grid' }}
					gridTemplateRows="auto auto 1fr"
				>
					{query.data.imageUrl ? <Avatar mixBlendMode="multiply" mb={4} size="md" src={query.data.imageUrl} /> : null}

					<Heading mb={4} fontSize="5xl" color="white">
						{query.data.name}
					</Heading>

					<Text fontSize="xl" fontWeight={500} color="white">
						{query.data.description}
					</Text>

					<Text color="white" mt="auto" fontWeight="600">
						Website created using{' '}
						<a target="_blank" href="//www.timetime.in" style={{ fontWeight: 900 }}>
							TimeTime.in
						</a>
					</Text>
				</Box>
				{hasAvailability ? (
					<BookingForm
						locale={locale}
						start={start.toJSDate()}
						end={end.toJSDate()}
						eventTypeTimezone={query.data.eventTypeTimeZone}
						onBackClick={onBackClick}
						onBookingConfirmed={handleBookingCreated}
						questions={questions}
						timeZone={timeZone}
						privacyPolicyUrl={privacyPolicyUrl}
					/>
				) : (
					<NoAvailabilityInfo />
				)}
			</Box>
		</ChakraProvider>
	);
}
