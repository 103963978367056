import { Box, Button, FormControl, FormErrorMessage, FormLabel, Image, Input } from '@chakra-ui/react';
import { useState } from 'react';

import imageService from '@/services/image.service';
import IQuestion from '@/types/IQuestion';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface IFormQuestionProps {
	question: IQuestion;
	form: UseFormReturn<Record<string, string>, any>;
}

export default function FormQuestionImage(props: IFormQuestionProps) {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const src = props.form.watch(props.question.id);

	function handleClick() {
		setIsLoading(true);

		imageService
			.openUploadWidget()
			.then((res) => {
				setIsLoading(false);

				if (res.event === 'success') {
					props.form.setValue(props.question.id, res.info.secure_url);
				}
			})
			.catch(() => {
				toast.error('Error');
				setIsLoading(false);
			});
	}
	return (
		<FormControl mt="4" isInvalid={!!props.form.formState.errors[props.question.id]}>
			<FormLabel>{props.question.label}</FormLabel>
			<Input required type="hidden" {...props.form.register(props.question.id)} />
			<Box
				minH="100px"
				p="4"
				borderRadius="md"
				background="gray.100"
				display="grid"
				gridTemplateRows={src ? 'auto 1fr' : '1fr'}
				justifyContent="center"
				alignItems="center"
				overflow="auto"
			>
				{src ? (
					<Box p="4" h="200px">
						<Image margin="auto" maxH="100%" src={props.form.watch(props.question.id)} />
					</Box>
				) : null}

				{src ? (
					<Button
						onClick={() => {
							props.form.setValue(props.question.id, '');
						}}
					>
						{t('Remove')}
					</Button>
				) : (
					<Button isLoading={isLoading} colorScheme="primary" onClick={handleClick}>
						{t('Upload image')}
					</Button>
				)}
			</Box>

			<FormErrorMessage>{props.form.formState.errors[props.question.id]?.message}</FormErrorMessage>
		</FormControl>
	);
}
