import AppLoader from '@/components/AppLoader';
import ITimeZone from '@/types/ITimeZone';
import { Box, ChakraProvider, Container } from '@chakra-ui/react';
import BookingPageFooter from './components/footer/booking-page-footer';
import BookingPageHeader from './components/header/header.view';
import WeekGrid from './components/week-grid/week-grid.view';
import useBookingPage from './useBookingPage1';

export default function BP1() {
	const {
		availabilityQuery,
		from,
		isLoading,
		locale,
		query,
		selectedDay,
		theme,
		timeZone,
		units,
		handleBackClick,
		handleDayClick,
		handleLocaleChange,
		handleNextClick,
		handlePrevClick,
		handleSlotClick,
		handleTimeZoneChange,
		handleUnitsChange,
	} = useBookingPage();

	if (!query.data) {
		return <AppLoader />;
	}

	return (
		<ChakraProvider theme={theme}>
			<Box w="100%" h="100%" backgroundColor="white" overflow="auto">
				<Container
					opacity={isLoading ? 0.75 : 1}
					maxW="container.lg"
					px={{ base: 0, lg: 20 }}
					display="grid"
					gridTemplateRows="auto auto 1fr"
					flexDir="column"
					h="100dvh"
					overflow="hidden"
				>
					<BookingPageHeader
						name={query.data.name}
						description={query.data.description}
						imageUrl={query.data.imageUrl ?? ''}
					/>

					<BookingPageFooter
						availableValues={query.data.i18n.availableLocales}
						locale={locale}
						timeZone={timeZone}
						onLocaleChange={handleLocaleChange}
						onTimezoneChange={handleTimeZoneChange}
					/>

					{availabilityQuery.data ? (
						<WeekGrid
							locale={locale}
							displayOnlyStart={query.data.eventTypeTags?.ttIntervalView !== 'range'}
							from={from}
							handleBackClick={handleBackClick}
							handleNextClick={handleNextClick}
							handlePrevClick={handlePrevClick}
							handleTimeZoneChange={(e) => handleTimeZoneChange(e.target.value as ITimeZone)}
							maxUnitsPerBooking={query.data.maxUnitsPerBooking ?? undefined}
							onDayClick={handleDayClick}
							onSlotClick={handleSlotClick}
							onUnitsChange={handleUnitsChange}
							selectedDay={selectedDay}
							slots={availabilityQuery.data.timeSlots}
							timeZone={timeZone}
							unitsLabel={query.data.unitsLabel ?? undefined}
							unitsValue={units}
						/>
					) : null}
				</Container>
			</Box>
		</ChakraProvider>
	);
}
