export const SECOND_MS = 1000;
export const MINUTE_MS = 60 * SECOND_MS;
export const HOUR_MS = 60 * MINUTE_MS;
export const DAY_MS = 24 * HOUR_MS;

export const LOCATION_QUESTION_ID = '_defaultLocationQuestionId';
export const BOOKER_PHONE_QUESTION_ID = 'tt_booker_phone';

/**
 * CHANGA: Se usa para representar el FIXED_PHONE
 */
export const BOOKER_PHONE_QUESTION_ID_CHANGA = 'tt_booker_phone_fixed';
/**
 * Se usa en el frontend para identificar la pregunta EMAIL.
 *
 * Esta pregunta va a parte en el backend.
 */
export const BOOKER_EMAIL_QUESTION_ID = 'tt_booker_email';
/**
 * Se usa en el frontend para identificar la pregunta notes.
 *
 * Esta pregunta va a parte en el backend.
 */
export const BOOKER_NOTES_QUESTION_ID = 'tt_booker_notes';

/**
 * Se usa en el frontend para saber en que momento empieza una reserva.
 * Se modela como una pregunta oculta.
 */
export const BOOKING_START_QUESTION_ID = 'tt_booking_start_time';

/**
 * Configuración para el componente de Cloudinary
 */
export const CLOUDINARY_CONFIG = {
	cloudName: 'iagolast',
	uploadPreset: 'timetime',
	sources: ['local', 'url', 'camera'],
	multiple: false,
	singleUploadAutoClose: true,
};

/**
 * Minimum score to consider a slot as available.
 */
export const MIN_SLOT_SCORE = 0.5;
