// Login
export const ROUTE_LOGIN = '/login';

// SignUp

/** This is a private route to login with username/password */
export const ROUTE_SIGN_UP = '/signup';

/**
 * Route to auto login from an iframe using a token
 */
export const ROUTE_LOGIN_WITH_TOKEN = '/login-with-token';

// Book
export const ROUTE_GRID = '/book/:id';

/**
 * New booking page route. Equivalent to /book/:id
 *
 * /b/:username/:slug
 */
export const ROUTE_BOOKING_PAGE = '/b/:id';

/**
 * When the booker selects a slot is redirected here.
 */
export const ROUTE_BOOKING_FORM = '/f/:id';

// Booking details
export const ROUTE_BOOKING_DETAILS = '/booking-details/:id';

// Dashboard
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_MICROSOFT_REDIRECT = '/dashboard/microsoft-redirect';
export const ROUTE_DASHBOARD_HOME = '/dashboard/home';
export const ROUTE_DASHBOARD_PROFILE = '/dashboard/home/profile';
export const ROUTE_DASHBOARD_PARTNERS = '/dashboard/home/partners';
export const ROUTE_DASHBOARD_PROFILE_CALENDARS = '/dashboard/home/profile/calendars';
export const ROUTE_DASHBOARD_PROFILE_DEVELOPERS = '/dashboard/home/profile/developers';
export const ROUTE_DASHBOARD_BOOKING_PAGE_HELPER = '/dashboard/home/booking-page-helper';

export const ROUTE_DASHBOARD_TEAMS_PAGE = '/dashboard/home/teams';
export const ROUTE_DASHBOARD_TEAM_DETAILS = '/dashboard/home/teams/:id';

export const ROUTE_DASHBOARD_ACCEPT_INVITE_LEGACY_PAGE = '/dashboard/accept-team-invite/:id';
export const ROUTE_DASHBOARD_ACCEPT_INVITE_PAGE = '/dashboard/home/accept-team-invite/:id';

// Services
export const ROUTE_DASHBOARD_BOOKING_PAGES = '/dashboard/home/booking-pages';
export const ROUTE_DASHBOARD_BOOKING_PAGE = '/dashboard/home/booking-pages/:id';

// Bookings
export const ROUTE_DASHBOARD_BOOKINGS = '/dashboard/home/bookings';
export const ROUTE_DASHBOARD_BOOKING = '/dashboard/home/bookings/:id';

// Onboarding
export const ROUTE_ONBOARDING = '/onboarding';
export const ROUTE_ONBOARDING_WELCOME = '/onboarding/welcome';
export const ROUTE_ONBOARDING_USERNAME = '/onboarding/username';
export const ROUTE_ONBOARDING_AVAILABILITY = '/onboarding/availability';
export const ROUTE_ONBOARDING_CALENDARS = '/onboarding/calendars';

// Resources
export const ROUTE_RESOURCES = '/dashboard/home/resources';
export const ROUTE_RESOURCES_DETAILS = '/dashboard/home/resources/:id';

// Availability
export const ROUTE_AVAILABILITY = '/dashboard/home/availability';
export const ROUTE_AVAILABILITY_DETAILS = '/dashboard/home/availability/:id';

// Availability groups
export const ROUTE_AVAILABILITY_GROUPS = '/dashboard/home/availability-groups';
export const ROUTE_AVAILABILITY_GROUPS_DETAILS = '/dashboard/home/availability-groups/:id';

// Resources
export const ROUTE_RESOURCE_GROUPS = '/dashboard/home/resource-groups';
export const ROUTE_RESOURCES_GROUP_DETAILS = '/dashboard/home/resource-groups/:id';

// Root Pages
export const ROUTE_ROOT_PAGES_LIST = '/dashboard/home/root-pages';
export const ROUTE_ROOT_PAGES_DETAILS = '/dashboard/home/root-pages/:id';
export const ROUTE_ROOT_PAGE_PUBLIC = '/rp/:id';

// Invitations
export const ROUTE_INVITATION_DETAILS = '/i/:id';

// Calendars
export const ROUTE_CALENDAR_LIST = `${ROUTE_DASHBOARD_HOME}/calendars`;
export const ROUTE_CALENDAR_DETAILS = `${ROUTE_DASHBOARD_HOME}/calendar`;



