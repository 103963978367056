import client from '@/api_client/client';
import { EP_EVENT, EP_EVENTS } from '@/constants/endpoints';
import EventSummary from '@/types/EventSummary';
import IEventType from '@/types/IEventType';
import { IEventTypeWrite } from '@/types/apiClient/IEventTypeWrite';
import eventParser from './eventType.parser';

function create(event: IEventTypeWrite) {
	return update(event);
}

export interface ListEventResponse {
	eventTypes: Array<EventSummary>;
}

async function details(id: string): Promise<IEventType> {
	return client
		.get<any>(`${EP_EVENT}/${id}`)
		.then((res) => res.data)
		.then((response) => {
			response.questions = eventParser.decodeQuestions(response.questions);
			response.tags = eventParser.decodeTags(response.tags);
			return response;
		});
}

function list(ownerId: string): Promise<ListEventResponse> {
	const params = new URLSearchParams();
	params.set('ownerId', ownerId);
	return client.get(`${EP_EVENTS}/?${params.toString()}`).then((res) => res.data);
}

function update(event: IEventTypeWrite) {
	return client.put(`${EP_EVENT}/${event.id}`, {
		...event,
		questions: eventParser.encodeQuestions(event.questions),
		imageUrl: event.imageUrl || null, // Server expects imageURL to be null or a valid string
	});
}

function remove(id: string): Promise<object> {
	return client.delete(`${EP_EVENT}/${id}`);
}

export default {
	create,
	list,
	details,
	remove,
	update,
};
