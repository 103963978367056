import { Box, Container, Heading, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';

export default function NoAvailabilityInfo() {
	return (
		<Box w="full">
			<Container maxW="container.md" py={20} px={8}>
				<VStack spacing={4} align="center" textAlign="center">
					<Box>
						<Heading fontWeight="bold">{t('No available spots left')}</Heading>

						<Text mt={8} color="gray.600">
							{t('Unfortunately, there is no availability at this time.')}
						</Text>
					</Box>
				</VStack>
			</Container>
		</Box>
	);
}
