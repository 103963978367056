import { ILocale } from '@/constants/locales';
import calendarService from '@/services/calendar/calendar.service';
import ITimeZone from '@/types/ITimeZone';
import ISlot from '@/types/Slot';
import { Box, Button, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

interface IDaySlotProps {
	onSlotClick: (slot: ISlot) => void;
	displayOnlyStart: boolean;
	locale: ILocale;
	selectedDay: DateTime | undefined;
	slots: Array<ISlot>;
	timeZone: ITimeZone;
}

export default function DaySlots(props: IDaySlotProps) {
	const { t } = useTranslation();

	if (!props.selectedDay) {
		return (
			<Box p={4} w="100%" overflow="auto">
				<Text fontSize="sm" color="gray.600" align={{ base: 'center', lg: 'left' }}>
					{t('Select a day to see the available slots.')}
				</Text>
			</Box>
		);
	}

	return (
		<Box
			data-id="DAYS"
			p={8}
			mx="auto"
			minW={{ base: '100%' }}
			maxW={{ base: '100%' }}
			h="full"
			margin="auto"
			overflow="auto"
		>
			{props.slots.map((slot) => {
				return props.displayOnlyStart ? (
					<Button
						variant="outline"
						mx="auto"
						key={slot.start.toISO()!}
						w="100%"
						mb="2"
						colorScheme="primary"
						onClick={() => props.onSlotClick(slot)}
					>
						{calendarService.formatDate({
							date: slot.start.toJSDate(),
							locale: props.locale,
							options: {
								hour: '2-digit',
								minute: '2-digit',
								timeZone: props.timeZone,
							},
						})}
					</Button>
				) : (
					<Button
						variant="outline"
						mx="auto"
						key={slot.start.toISO()!}
						w="100%"
						mb="2"
						colorScheme="primary"
						onClick={() => props.onSlotClick(slot)}
					>
						{calendarService
							.formatDate({
								date: slot.start.toJSDate(),
								locale: props.locale,
								options: {
									hour12: false,
									hour: '2-digit',
									minute: '2-digit',
									timeZone: props.timeZone,
								},
							})
							.replace(/ AM| PM/, '')}{' '}
						-{' '}
						{calendarService.formatDate({
							date: slot.end.toJSDate(),
							locale: props.locale,
							options: {
								hour: '2-digit',
								minute: '2-digit',
								timeZone: props.timeZone,
							},
						})}
					</Button>
				);
			})}
		</Box>
	);
}
