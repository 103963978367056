import { EP_BOOKINGS } from '@/constants/endpoints';
import IBooking from '@/types/IBooking';
import { AxiosResponse } from 'axios';
import client from '../api_client/client';

interface IListArgs {
	eventTypesUserId: string;
	/**
	 * Instant to get the bookings from
	 */
	from?: Date | string;
	days?: number;
}

async function list(args: IListArgs): Promise<Array<IBooking>> {
	const params = new URLSearchParams();
	params.set('eventTypesUserId', args.eventTypesUserId);

	if (args.from) {
		typeof args.from === 'string'
			? params.set('from', new Date(args.from).toISOString())
			: params.set('from', args.from.toISOString());
	}

	if (args.days) {
		params.set('days', args.days.toString());
	}

	const raw = await client.get<any>(`${EP_BOOKINGS}?${params.toString()}`).then((res) => res.data);

	return raw.bookings.map((rawBooking: any) => ({
		...rawBooking,
		interval: { start: new Date(rawBooking.interval.start), end: new Date(rawBooking.interval.end) },
	}));
}

interface ICreateBookingParams {
	answers: Record<string, string>;
	eventTypeId: string;
	mail: string;
	notes: string;
	start: Date;
	units?: number;
}

async function create(params: ICreateBookingParams) {
	return client.post<any, AxiosResponse<{ id: string }>>(`${EP_BOOKINGS}`, {
		answers: params.answers,
		bookerEmail: params.mail,
		eventTypeId: params.eventTypeId,
		notes: params.notes,
		start: params.start.toISOString(),
		units: params.units,
	});
}

async function get(id: string): Promise<IBooking> {
	return client.get<any>(`${EP_BOOKINGS}/${id}`).then((res) => ({
		...res.data,
		interval: {
			start: new Date(res.data.interval.start),
			end: new Date(res.data.interval.end),
		},
	}));
}

async function cancel(args: { id: string; reason: string }): Promise<IBooking> {
	return client
		.post(`${EP_BOOKINGS}/${args.id}/cancellation`, {
			reason: args.reason,
		})
		.then((res) => res.data);
}

async function update(args: { id: string; privateNotes: Array<string> }) {
	return client
		.patch(`${EP_BOOKINGS}/${args.id}`, {
			privateNotes: args.privateNotes,
		})
		.then((res) => res.data);
}

export default { update, create, list, get, cancel };
