import { ILocale } from '@/constants/locales';
import { TTD, TTE, TTF, TTLANG, TTS, TTU, TTZ } from '@/constants/queryParams';
import { ROUTE_BOOKING_FORM } from '@/constants/routes';
import useBookingPagePreview from '@/hooks/useBookingPagePreview';
import { useSearchParams } from '@/hooks/useSearchParams';
import i18n from '@/i18n';
import { useGetPublicEventTypeInfo } from '@/queries/eventTypes.queries';
import { useAvailability } from '@/queries/useAvailability';
import queryParamsService from '@/services/queryParams.service';
import timeZoneService from '@/services/timezone.service';
import timetimeTheme from '@/themes';
import ITimeZone from '@/types/ITimeZone';
import ISlot from '@/types/Slot';
import { extendTheme } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useHistory, useParams } from 'react-router-dom';

export default function useBookingPage() {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const params = useSearchParams();
	const timeZone = params.get(TTZ) ? (params.get(TTZ) as ITimeZone) : timeZoneService.getTimeZone();
	const from = params.get(TTF)
		? DateTime.fromISO(params.get(TTF)!, { zone: timeZone })
		: DateTime.fromJSDate(new Date(), { zone: timeZone });

	const units = params.has(TTU) ? Number(params.get(TTU)) : undefined;
	const locale = (params.get(TTLANG) as ILocale) ?? (i18n.language as ILocale);
	const query = useGetPublicEventTypeInfo({ id, locale });
	const availabilityQuery = useAvailability({ id, from, units });
	const color = query.data?.eventTypeTags?.ttTheme;
	const queryClient = useQueryClient();
	const { isPreviewLoading } = useBookingPagePreview({ queryClient });
	const selectedDay = params.get(TTD) ? DateTime.fromISO(params.get(TTD)!, { zone: timeZone }) : from;

	function handleSlotClick(slot: ISlot) {
		const search = queryParamsService.buildSearchParams({
			params: {
				[TTS]: slot.start.toISO()!,
				[TTE]: slot.end.toISO()!,
			},
			originalParams: params,
		});

		history.push({
			pathname: ROUTE_BOOKING_FORM.replace(':id', id),
			search,
		});
	}

	function handleNextClick() {
		const search = queryParamsService.buildSearchParams({
			params: {
				[TTF]: from.plus({ days: 7 }).toISODate()!,
			},
			originalParams: params,
		});
		history.push({ search });
	}

	function handlePrevClick() {
		const search = queryParamsService.buildSearchParams({
			params: {
				[TTF]: from.minus({ days: 7 }).toISODate()!,
			},
			originalParams: params,
		});
		history.push({ search });
	}

	function handleUnitsChange(e: React.ChangeEvent<HTMLSelectElement>) {
		const search = queryParamsService.buildSearchParams({
			params: {
				[TTU]: e.target.value,
			},
			originalParams: params,
		});

		history.push({ search });
	}

	function handleTimeZoneChange(value: ITimeZone) {
		const search = queryParamsService.buildSearchParams({
			params: { [TTZ]: value },
			originalParams: params,
		});
		history.push({ search });
	}

	function handleBackClick() {
		history.goBack();
	}

	function handleDayClick(day: DateTime) {
		const search = queryParamsService.buildSearchParams({
			params: {
				[TTD]: day ? day.toISODate()! : '',
				[TTZ]: day ? day.zoneName! : '',
			},
			originalParams: params,
		});

		history.push({ search });
	}

	function handleLocaleChange(value: ILocale) {
		const search = queryParamsService.buildSearchParams({
			params: {
				[TTLANG]: value,
			},
			originalParams: params,
		});
		i18n.changeLanguage(value);
		history.push({ search });
	}

	return {
		handleDayClick,
		theme: color
			? extendTheme({
					...timetimeTheme,
					colors: { ...timetimeTheme.colors, primary: timetimeTheme.colors[color] },
			  })
			: timetimeTheme,
		isLoading: query.isLoading || availabilityQuery.isLoading || isPreviewLoading,
		query,
		availabilityQuery,
		timeZone,
		units,
		selectedDay,
		from,
		locale,
		handleBackClick,
		handleLocaleChange,
		handleNextClick,
		handlePrevClick,
		handleSlotClick,
		handleTimeZoneChange,
		handleUnitsChange,
	};
}
