import { ILocale } from '@/constants/locales';
import { TTD, TTE, TTF, TTLANG, TTS, TTU, TTZ } from '@/constants/queryParams';
import { ROUTE_BOOKING_FORM } from '@/constants/routes';
import useBookingPagePreview from '@/hooks/useBookingPagePreview';
import { useSearchParams } from '@/hooks/useSearchParams';
import i18n from '@/i18n';
import { useGetPublicEventTypeInfo } from '@/queries/eventTypes.queries';
import { useAvailability } from '@/queries/useAvailability';
import { IDay } from '@/services/calendar/calendar.service';
import dateService from '@/services/date/date.service';
import gridService from '@/services/grid.service';
import queryParamsService from '@/services/queryParams.service';
import timeZoneService from '@/services/timezone.service';
import timetimeTheme from '@/themes';
import ITimeZone from '@/types/ITimeZone';
import ISlot from '@/types/Slot';
import { extendTheme } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useHistory, useParams } from 'react-router-dom';

export default function useBookingPage() {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const params = useSearchParams();
	const units = params.has(TTU) ? Number(params.get(TTU)) : undefined;
	const timeZone = params.get(TTZ) ? (params.get(TTZ) as ITimeZone) : timeZoneService.getTimeZone();
	const selectedDay = params.get(TTD) ? DateTime.fromISO(params.get(TTD)!, { zone: timeZone }) : undefined;
	const from = params.get(TTF)
		? DateTime.fromISO(params.get(TTF)!, { zone: timeZone })
		: DateTime.fromJSDate(new Date(), { zone: timeZone });

	const days = 42 - from.day;
	const locale = i18n.language as ILocale;

	/**
	 *	TODO: Hay un bug en el from.
	 * 	En realidad hay que pillar el primer dia que se muestra en el calendario (que puede ser el mes pasado).
	 *  Si no pedimos la disponibilidad del mes pasado queda la primera semana de la UI vacía.
	 */
	const query = useGetPublicEventTypeInfo({ id, locale });
	const availabilityQuery = useAvailability({ id, from, units, days });
	const color = query.data?.eventTypeTags?.ttTheme;
	const grid = gridService.organiseSlots(availabilityQuery.data?.timeSlots || [], timeZone);
	const queryClient = useQueryClient();
	const daySlots = getDaySlots(availabilityQuery.data?.timeSlots, timeZone, selectedDay);
	const { isPreviewLoading } = useBookingPagePreview({ queryClient });

	function handleSlotClick({ start, end }: { start: DateTime; end: DateTime }) {
		const search = queryParamsService.buildSearchParams({
			params: {
				[TTS]: start.toISO()!,
				[TTE]: end.toISO()!,
			},
			originalParams: params,
		});
		history.push({
			pathname: ROUTE_BOOKING_FORM.replace(':id', id),
			search,
		});
	}

	function handleDayClick(day: IDay | null) {
		const search = queryParamsService.buildSearchParams({
			params: {
				[TTD]: day ? day.dt.toISODate()! : '',
				[TTZ]: day ? day.timeZone : '',
			},
			originalParams: params,
		});

		history.push({ search });
	}

	function handleNextClick() {
		from.set({ month: from.month + 1, day: 1 });

		const jump = DateTime.fromObject({
			year: from.month === 12 ? from.year + 1 : from.year,
			month: from.month === 12 ? 1 : from.month + 1,
			day: 1,
		});

		const paramsCopy = new URLSearchParams(params.toString());
		paramsCopy.delete(TTD);

		const search = queryParamsService.buildSearchParams({
			params: {
				[TTF]: jump.toISODate()!,
			},
			originalParams: paramsCopy,
		});

		history.push({ search });
	}

	function handlePrevClick() {
		const jump = DateTime.fromObject({
			year: from.month === 1 ? from.year - 1 : from.year,
			month: from.month === 1 ? 12 : from.month - 1,
			day: 1,
		});

		const paramsCopy = new URLSearchParams(params.toString());
		paramsCopy.delete(TTD);

		const search = queryParamsService.buildSearchParams({
			params: {
				[TTF]: jump.toISODate()!,
			},
			originalParams: paramsCopy,
		});
		history.push({ search });
	}

	function handleUnitsChange(e: React.ChangeEvent<HTMLSelectElement>) {
		const search = queryParamsService.buildSearchParams({
			params: {
				[TTU]: e.target.value,
			},
			originalParams: params,
		});

		history.push({ search });
	}

	function handleTimeZoneChange(e: React.ChangeEvent<HTMLSelectElement>) {
		const search = queryParamsService.buildSearchParams({
			params: {
				[TTZ]: e.target.value,
			},
			originalParams: params,
		});
		history.push({ search });
	}

	function handleLocaleChange(value: ILocale) {
		const search = queryParamsService.buildSearchParams({
			params: { [TTLANG]: value },
			originalParams: params,
		});
		i18n.changeLanguage(value);
		history.push({ search });
	}

	function handleBackClick() {
		history.goBack();
	}

	return {
		theme: extendTheme({
			...timetimeTheme,
			colors: { ...timetimeTheme.colors, primary: timetimeTheme.colors[color as any] },
		}),
		availabilityQuery,
		daySlots,
		from,
		grid,
		isLoading: query.isLoading || availabilityQuery.isLoading || isPreviewLoading,
		locale,
		query,
		selectedDay,
		slotEnd: params.get(TTE) ? new Date(params.get(TTE)!) : undefined,
		slotStart: params.get(TTS) ? new Date(params.get(TTS)!) : undefined,
		timeZone,
		units: units,
		handleLocaleChange,
		handleBackClick,
		handleNextClick,
		handlePrevClick,
		handleSlotClick,
		handleTimeZoneChange,
		handleUnitsChange,
		handleDayClick,
	};
}

function getDaySlots(timeSlots: Array<ISlot> | undefined, timeZone: string, selectedDay: DateTime | undefined) {
	if (!selectedDay || !timeSlots) {
		return [];
	}

	const slots = [];

	for (const slot of timeSlots) {
		// Slots are sorted, if we pass the day we can skip the rest
		if (slot.start.toJSDate() >= selectedDay.plus({ days: 1 }).toJSDate()) {
			return slots;
		}

		if (slot.score <= 0) {
			continue;
		}

		if (dateService.isSameDay(slot.start.toJSDate(), selectedDay.toJSDate(), timeZone)) {
			slots.push(slot);
		}
	}

	return slots;
}
